import React from "react";
import { FormattedMessage } from "react-intl";

const ButtonAdd = (props) => (
    <button className="btn btn-primary input-group-btn">
        <i className="icon icon-plus" /> <FormattedMessage id="dashboard.add" />
    </button>
);

export { ButtonAdd };
